
import { defineComponent, onMounted } from "vue";
 

export default defineComponent({
  name: "genealogy-table",
  components: { 
  },
  data() { 
  },
    props: { 
        data: {type: Array, required: true}
  },
  mounted() {
      
  }
});
