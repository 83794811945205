
import { computed, defineComponent, onMounted, ref } from "vue";
import { StepperComponent } from "@/assets/ts/components/_StepperComponent";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { useForm } from "vee-validate";
import { Field, ErrorMessage } from "vee-validate";
import * as Yup from "yup";

import {getcountries, getcities}  from "@/api/common";
import { validateMultiple, saveRegMultiple}  from "@/api/register";


import ConfirmRegisterAddAccountEC from "@/views/auth/ConfirmRegisterAddAccountEC.vue";

interface Step1 { 
  spid: string;
  upid: string;
  position: string;
}

interface Step2 { 
  
 

}

interface Step3 {
  usid: string;
  secpin: string;
  verifyingdata: boolean; 
  responsedata: object;
}

interface Step4 {
}

interface KTCreateApp extends Step1, Step2, Step3, Step4 {}

export default defineComponent({
  name: "create-account-modal",
  data() {
    return {   
        address_country: "",
        confirm_data: {} 

    }
  },
  components: {
    Field,
    ErrorMessage,
    ConfirmRegisterAddAccountEC
  },
  props: { 
        slotdata: Object
  },
  setup(props, context) {
    const _stepperObj = ref<StepperComponent | null>(null);
    const createAccountRef = ref<HTMLElement | null>(null);
    const currentStepIndex = ref(0);

    const countries = ref([]) as any; 
    const cities = ref([]) as any; 

    
    const isDisabledUPID = props.slotdata !== undefined && props.slotdata.upid !== undefined;

    const httpGetCountries =  async () => { 
      countries.value = [];
        const response = await getcountries(); 
        if(response.data !== undefined && response.data.countries !== undefined) {
          countries.value = response.data.countries; 
        }
       
    }; 


    const getCities = async(country_id) => {  
        cities.value = [];
        const response = await getcities(country_id); 
        if(response.data !== undefined && response.data.cities !== undefined) {
            cities.value = response.data.cities; 
        }
    };


    const formData = ref<KTCreateApp>({   
      
      verifyingdata: false,
      responsedata: {},
      spid:  (props.slotdata !== undefined && props.slotdata.spid !== undefined) ? props.slotdata.spid : "",
      upid: (props.slotdata !== undefined && props.slotdata.upid !== undefined) ? props.slotdata.upid : "",
      position: (props.slotdata !== undefined && props.slotdata.position !== undefined) ? props.slotdata.position : "",
      
      usid: "",
      secpin: ""
    });
 
    onMounted(() => {
      _stepperObj.value = StepperComponent.createInsance(
        createAccountRef.value as HTMLElement
      );   
      console.log("mounted");
      if(props.slotdata !== undefined && props.slotdata.upid !== undefined) {
          formData.value.upid = props.slotdata.upid;
          formData.value.spid = props.slotdata.spid;
          formData.value.position = props.slotdata.position;
      }
    });

    const createAppSchema = [
      Yup.object({}),  
      
      Yup.object({ 
        spid: Yup.string()
          .required()
          .max(20)
          .label("Sponsor User ID"),
        upid: Yup.string()
          .required()
          .max(20)
          .label("Upline User ID"),
        position: Yup.string()
          .required()
          .label("Position")
      }), 

     

      Yup.object({ 
        usid: Yup.string()
          .required()
          .max(20)
          .label("Activation User ID"),
        secpin: Yup.string()
          .required()
          .max(20)
          .label("Activation PIN") 


      }) 
    ];

    // extracts the individual step schema
    const currentSchema = computed(() => {
      return createAppSchema[currentStepIndex.value];
    });

    const totalSteps = computed(() => {
      if (!_stepperObj.value) {
        return;
      }

      return _stepperObj.value.totatStepsNumber;
    });

    const { resetForm, handleSubmit } = useForm<Step1 | Step2 | Step3 | Step4>({
      validationSchema: currentSchema
    });

    const previousStep = () => {
      if (!_stepperObj.value) {
        return;
      }

      currentStepIndex.value--;

      _stepperObj.value.goPrev();
    };

    const handleStep = handleSubmit(values => {
      for (const item in values) {
        // eslint-disable-next-line no-prototype-builtins
        if (values.hasOwnProperty(item)) {
          if (values[item]) {
            formData.value[item] = values[item];
          }
        }
      }

      currentStepIndex.value++;
 

      if (!_stepperObj.value) {
        return;
      }

      
      if(currentStepIndex.value === 2){ 
        validateAddAccount(formData.value);
      }

      _stepperObj.value.goNext();
    });
    
    
const validateAddAccount = async (data: any) => { 
      formData.value.verifyingdata = true;
      const response: any =   await validateMultiple(data);
      formData.value.verifyingdata = false;

      if(response.data !== undefined && response.data.result === "FAILED") {  
          Swal.fire({
            title: "",
            text: response.data.message,
            icon: "error",
            confirmButtonClass: "btn btn-secondary"
          });  
           if (!_stepperObj.value) {
              return;
           }
           currentStepIndex.value = 0;
          _stepperObj.value.goFirst();
          return;
      }  
      
      if(response.data !== undefined && response.data.result === "SUCCESS") {
          formData.value.responsedata = response.data;
          console.log(response.data);
      }

    };

    const formSubmit = async () => {
      const response: any =   await saveRegMultiple(formData.value);
       
      if(response.data !== undefined && response.data.result === "FAILED") {  
          Swal.fire({
            title: "",
            text: response.data.message,
            icon: "error",
            confirmButtonClass: "btn btn-secondary"
          });  
           if (!_stepperObj.value) {
              return;
           }
           currentStepIndex.value = 0;
          _stepperObj.value.goFirst();
          return;
      }  
      
      if(response.data !== undefined && response.data.result === "SUCCESS") {
              Swal.fire({
                text: response.data.message,
                icon: "success",
                buttonsStyling: false,
                confirmButtonText: "Ok",
                customClass: {
                  confirmButton: "btn fw-bold btn-light-primary"
                }
              }).then(() => {
                window.location.reload();
              });
      }

      
 
    };

    resetForm({
      values: {
        ...formData.value
      }
    });

    return {
      createAccountRef,
      totalSteps,
      previousStep,
      handleStep,
      formSubmit,
      currentStepIndex,
      formData,  
      countries,
      getCities,
      cities,
      isDisabledUPID
    };
  },
  methods: {
     

    
  },
  mounted() { 
    //this.getcountries(); 
   // this.httpGetCountries();
  },
});
