<template >
<!-- <CompanyPolicy></CompanyPolicy> -->
<div class="col-xxl-12 mb-5">
    <!-- <ECShopAdvisory></ECShopAdvisory> -->
  </div>
  <div v-show="loading"> 
 	<el-skeleton :rows="10" animated />
  </div>	
  <div class="container" v-show="!loading && packagetype !== 'BIBO'"> 
    <div id="genealogy-chart">
    <GChart  
      :resizeDebounce="500"
      :settings="{ packages: ['orgchart'], callback: ()=>{this.drawChart()} }"
      :events="chartEvents"
      type="OrgChart"
      :data="chartData"  
      ref="gChart" 

    />
    </div>
	
	<div  class="card mt-10">
					<div class="card-header cursor-pointer">
					<!--begin::Card title-->
					<div class="card-title m-0">
						<h3 class="fw-bolder m-0"></h3>

						<input type="text" v-model="searchKey" class="form-control form-control-lg form-control-solid mr-10"
						placeholder="User ID"/> 
					<!--begin::Actions-->
					<div class="d-flex align-items-center py-1">
						<!--begin::Wrapper-->
						<div class="me-4">
						<!--begin::Menu-->
						<button
							@click="search();"
							class="btn btn-sm btn-flex btn-light btn-active-primary fw-bolder" 
							data-kt-menu-placement="bottom-end"
							data-kt-menu-flip="top-end"
						>
							<span class="svg-icon svg-icon-5 svg-icon-gray-500 me-1">
							<inline-svg src="media/icons/duotone/Text/Filter.svg" />
							</span>
							Search
						</button>
 
						<!--end::Menu-->
						</div>
						<!--end::Wrapper-->

						<!--begin::Button--> 
						<!-- router-link to="/networkmenu/genealogy?usid={usid}" class="btn btn-sm btn-primary">
							Home
						</router-link -->
						<button 
							@click="gotoUserId('');"
							class="btn btn-sm btn-primary" 
						>
						Home
						</button>
						<!--end::Button-->
						
						<!--begin::Button-->
						<button
							v-if="upOneLevel !== ''"
							@click="gotoUserId(upOneLevel);"
							class="btn btn-sm btn-secondary" 
						>
						Up
						</button>
						<!--end::Button-->
					</div>
					<!--end::Actions-->
					</div>
					<!--end::Card title-->

					</div>
					<!--begin::Card header-->
	</div>

  	<GenealogyTable :data="genealogyRows"></GenealogyTable>


	<button type="button" ref="chooseAccountTypeModalButton"  v-show="false"  class="btn btn-primary er fs-6 px-8 py-4"  data-bs-toggle="modal" :data-bs-target="`#kt_modal_choose_account_type`"  >
	Click here
	</button>
	<ChooseAccountTypeModal :key="modalkey" 
							@accountTypeSelected ="accountTypeSelected($event)" 
	></ChooseAccountTypeModal>


	<button type="button" ref="registerNewECButton"  v-show="false"  class="btn btn-primary er fs-6 px-8 py-4"  data-bs-toggle="modal" :data-bs-target="`#kt_register_account_modal_ec`"  >
	Click here
	</button>
	 <RegisterAccountModalEC :key="modalkey" :slotdata="slotdata">
	 </RegisterAccountModalEC>

	 <button type="button" ref="registerAddAccountECButton"  v-show="false"  class="btn btn-primary er fs-6 px-8 py-4"  data-bs-toggle="modal" :data-bs-target="`#kt_add_account_modal_ec`"  >
	Click here
	</button>
	 <RegisterAddAccountModalEC :key="modalkey" :slotdata="slotdata">
	 </RegisterAddAccountModalEC>

	 <button type="button" ref="registerAccountOVIButton"  v-show="false"  class="btn btn-primary er fs-6 px-8 py-4"  data-bs-toggle="modal" :data-bs-target="`#kt_register_account_modal`"  >
	Click here
	</button>
	 <RegisterModal :key="modalkey" :slotdata="slotdata">
	 </RegisterModal>

	 <button type="button" ref="addAccountOVIButton"  v-show="false"  class="btn btn-primary er fs-6 px-8 py-4"  data-bs-toggle="modal" :data-bs-target="`#kt_addovi_account_modal`"  >
	Click here
	</button>
	 <SealProEncode :key="modalkey" :slotdata="slotdata" :sealpro_encode_modal="'kt_addovi_account_modal'" :firstCoverage="firstCoverage"  :allowClose="true" :inputActivationDetail="false" :isAddAccount="true" :allowChangeName="false"></SealProEncode>
  </div>
  
</template>

<script  >
import { GChart } from 'vue-google-charts'

import  GenealogyTable  from '@/views/network/GenealogyTable.vue'
import ChooseAccountTypeModal from '@/views/modals/ChooseAccountTypeModal.vue'
import { genealogyapi} from '@/api/network.api'

import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
 
import RegisterAccountModalEC from "@/views/auth/RegisterModalEC.vue";
import RegisterAddAccountModalEC from "@/views/auth/RegisterAddAccountModalEC.vue";
import SealProEncode from "@/views/modals/SealProEncode.vue";
import RegisterModal from '../auth/RegisterModal.vue'
// import CompanyPolicy from "@/components/companypolicy/CompanyPolicy.vue";
// import ECShopAdvisory from "@/views/ECShopAdvisory.vue";

  export default {
    components: {
      GChart,
	  GenealogyTable,
	  ChooseAccountTypeModal,
	  RegisterAccountModalEC,
	  RegisterAddAccountModalEC,
	  SealProEncode,
        RegisterModal,
		// CompanyPolicy,
		// ECShopAdvisory
    },
    data () {
      return {
              // Array will be automatically processed with visualization.arrayToDataTable function
			chartData: null,
			usid: "xxx",
			genealogyRows: [],
			modalkey: 0, 
			takenSlot: [],
			downlines:[],
			searchKey: "",
			upOneLevel: "" ,
			googlechartkey: "xxx",
			loading: true,
			msg: "Please wait...", 
			ppsBackground: "#4caf50",
			currentAccount: "",
			emptyNodeBackground: "gray",
			notPPSBackground: "#2196f3",
			slotdata: {},

			selectedUPID: "",
			selectedSPID: "",
			selectedPOS: "",

			chartEvents: {
				select : () => {     
					console.log('select');      
				},
				click: () => {
					console.log('click');  
				} 
			},
			packagetype: ""  
      }
    },
    methods: { 
		  
		  gotoUserId(u){ 
				//window.location.href =   "/#/networkmenu/genealogy?usid=" + u;
				//location.reload();
				
				let l =  "/#/networkmenu/genealogy?usid=" + u;
				location.replace(l);
		  },
		  search() {
			  if(this.searchKey === "") return;
			  
				//window.location.href =   "/#/networkmenu/genealogy?usid=" + this.searchKey ;
				//location.reload(); 
				let l =  "/#/networkmenu/genealogy?usid=" + this.searchKey ;
				location.replace(l); 
			  //this.getGenealogy(this.searchKey);
		  },
		  encodeVacant(){
			    setTimeout(() => {    
					const btn = this.$refs.chooseAccountTypeModalButton;
					btn.click();
				}, 100);
		  },
		  registerNewEC(){
			    setTimeout(() => {    
					const btn = this.$refs.registerNewECButton;
					btn.click();
				}, 100);
		  } ,
		  registerAddAccountEC(){
			  setTimeout(() => {    
					const btn = this.$refs.registerAddAccountECButton;
					btn.click();
				}, 100);
		  },
		  registerOVI(){
			  setTimeout(() => {    
					const btn = this.$refs.registerAccountOVIButton;
					btn.click();
				}, 100);
		  },
		  addOVI(){
				setTimeout(() => {    
						const btn = this.$refs.addAccountOVIButton;
						btn.click();
					}, 100);
			},

		 accountTypeSelected(e) {   
			this.slotdata = {
				upid: this.selectedUPID,
				spid: this.selectedSPID,
				position: this.selectedPOS
			} 
			this.modalkey += 1;
			document.getElementById('kt_modal_choose_account_type_closebutton').click();
			if(e === 'regec') { 
				this.registerNewEC();
				return;
			} else if(e === 'addec'){
				this.registerAddAccountEC();
				return;
			} else if(e === 'regovi'){
				this.registerOVI();
				return;
			} else if(e === 'addovi'){
				this.addOVI();
				return;
			}
		  }, 
          drawChart() {
		
			this.initChart(); 
 
                // Create the chart.
            var chart = new google.visualization.OrgChart(document.getElementById('genealogy-chart'));
            // Draw the chart, setting the allowHtml option to true for the tooltips.
            chart.draw(this.chartData, {allowHtml:true, allowCollapse:false, size:'medium' });

			var self= this;
    		new google.visualization.events.addListener(chart, 'select', function() { 
					let selection = chart.getSelection();
					if(selection[0]!== undefined && selection[0].row !== undefined) {  
						let headno = selection[0].row + 1;
						if(self.downlines[headno] !== undefined)  { 
							//	window.location.href =   "/#/networkmenu/genealogy?usid=" + self.downlines[headno].usid;
							//	location.reload();
							let l =  "/#/networkmenu/genealogy?usid=" + self.downlines[headno].usid;
							location.replace(l);
						} else {
							let parent = 0;
							let position = "";
							if(headno % 2 != 0)	 {
								parent = (headno - 1) /2;
								position = "R";
							} else {
								parent = (headno) /2; 
								position = "L";
							}
							if(self.downlines[parent] !== undefined)  {   
								self.modalkey += 1;
								self.encodeVacant(); 
								self.selectedUPID =  self.downlines[parent].usid;
								self.selectedSPID = self.downlines[1].usid;
								self.selectedPOS = position; 
							}
						}
					}
			});

          },

		  initChart() {
			  	 
				if (this.chartData == null) {   	
					this.chartData = new google.visualization.DataTable();
					this.chartData.addColumn('string', 'Name')
					this.chartData.addColumn('string', 'Manager')
					this.chartData.addColumn('string', 'ToolTip')
				} 
 
		  },

		
		  

		async getGenealogy(u){
					this.loading = true;
					const response = await genealogyapi(u); 
					this.loading = false;
					 	this.upOneLevel = "";
						if(response.data !== undefined  && response.data.genealogyTreeArr !== undefined) {


							let data = JSON.parse(response.data.genealogyTreeArr) ; 

							this.genealogyRows = response.data.takenslot; 
							this.upOneLevel = response.data.uponelevel;
							
							
							this.initChart();
							this.takenSlot = response.data.takenslot;
							this.downlines = [];
							for(let i = 0; i < this.takenSlot.length; i++) {
								this.downlines[this.takenSlot[i].positionNo] = this.takenSlot[i];
							}
							
    						//this.chartData = new google.visualization.DataTable();
							/*for(let i = 0; i < data.length; i++) {
									let avatar = data[i].avatar;
									if(avatar.indexOf("default-avatar.png") > 0 || avatar === "") {
										avatar = "https://images.empoweredconsumerism-static-files.com/avatar/default-avatar.png";
									}
									this.chartData.addRow(
										[ {v: data[i].v + "", f: '<img alt="Logo" src="' + avatar + '" class="h-55px" /> <br/> ' + data[i].v } ,data[i].parent, data[i].tooltip]   
									); 
							} */ 
							let topaccountdiv = "";

							topaccountdiv = topaccountdiv + '<div>';
							topaccountdiv = topaccountdiv + '<img src="' +  this.downlines[1].avatar  + '"  style="width:48px; height:48px;">';
							topaccountdiv = topaccountdiv + '</div>';
							topaccountdiv = topaccountdiv +  this.downlines[1].usid + '<br/>'; 
							this.chartData.addRow(
								[ {v:   "1", f: topaccountdiv } ,'','']   
							); 


							for(let i = 2; i <= 31; i++) {
								let parent = "";
								if(i > 1) {

									if((i % 2) != 0) {
										parent =  (i - 1) / 2; 
									} else { 
										parent =  i / 2;
									}
								}  
				
								let label = "";
								let dragObj = "";
								if(this.downlines[i] !== undefined) {
									let draggableClass = ""; 
									
									dragObj = dragObj + '<div headno="' + i + '" id="draggable'+ this.downlines[i].usid +'"      >';
										dragObj = dragObj + '<img src="' +  this.downlines[i].avatar  + '"  style="width:48px; height:48px;">';
									dragObj = dragObj + '</div>' ;
									label = dragObj; 
				
								} else {

									dragObj = dragObj + '<div headno="' + i + '" id="draggable'+ i +'"  style="width:48px; height:48px;" >'; 
									dragObj = dragObj + '</div>' ;
									label = dragObj; 
								}



								this.chartData.addRow(
									[ {v: i + "", f: label + i} , parent + "", '']   
								); 
				

								if(this.downlines[i] !== undefined) {
									if(this.downlines[i].isPPS === 1){  
										this.chartData.setRowProperty(i-1, 'style', 'background: ' + this.ppsBackground + ' !important;');	//PPS background 
									}else {
										this.chartData.setRowProperty(i-1, 'style', 'background: ' + this.notPPSBackground + ' !important;');	 //NOT PPS background
									} 
								} else {
									this.chartData.setRowProperty(i-1, 'style', 'background: ' + this.emptyNodeBackground + ' !important;');	 //NOT PPS background
								}

							}

							this.drawChart(this.chartData);
							 
						}
		}
 	

      },
	mounted(){
		console.log("VAN");
		this.packagetype = localStorage.getItem("packagetype");
		setCurrentPageTitle("Genealogy");
  		 this.usid = this.$route.query.usid;
		 
		   if(this.usid === "" || this.usid === undefined) this.usid = "xxx";


		let self = this;
		setTimeout(() => {  
				self.getGenealogy(this.usid); 
		}, 100);
		   
		   
	},
	watch: { 
      	chartData: function(newVal, oldVal) { // watch it
          console.log('Prop changed: ', newVal, ' | was: ', oldVal)
        }
      }


  }

</script>

<style>
  table {
      border-collapse: inherit;
      border-spacing: 0;
  }
  .google-visualization-orgchart-node {
	  border: 2px solid #8fb2c3 !important;
	  background-color: none !important;
	  background:none !important;
  }
</style>