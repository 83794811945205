
import { computed, defineComponent, onMounted, ref } from "vue";
import { StepperComponent } from "@/assets/ts/components/_StepperComponent";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { useForm } from "vee-validate";
import { Field, ErrorMessage } from "vee-validate";
import * as Yup from "yup";

import {hasOVIPackage}  from "@/api/network.api";

interface Step1 {
  accountType: string;
}

interface Step2 { 
}

interface Step3 { 
}

interface Step4 { 
}

interface KTCreateApp extends Step1, Step2, Step3, Step4 {} 
export default defineComponent({
  name: "choose-account-type-modal",
  components: {
    Field,
    ErrorMessage
  },
  methods: { 
    async checkIfHasOVIPackage(){
      const response = await hasOVIPackage();
      console.log("hasOVIPackage");
      console.log(response.data.hasOVIPackage);

      this.hasOVIPackage = response.data.hasOVIPackage;
    }
  },
  mounted(){
      this.checkIfHasOVIPackage();
  },
  data(){
      return { 
        hasOVIPackage : false,
      }
  },
  setup(props, context) {
    const _stepperObj = ref<StepperComponent | null>(null);
    const createAccountRef = ref<HTMLElement | null>(null);
    const currentStepIndex = ref(0);

    const formData = ref<KTCreateApp>({
      accountType: "addovi" 
    });

    const planType  = computed(() => { 
      let pt = localStorage.getItem('pt'); 
      return pt;
    });


    onMounted(() => {
      _stepperObj.value = StepperComponent.createInsance(
        createAccountRef.value as HTMLElement
      );
    });

    const createAppSchema = [ 
    ];

    
    // extracts the individual step schema
    const currentSchema = computed(() => {
      return createAppSchema[currentStepIndex.value];
    });

    const totalSteps = computed(() => {
      if (!_stepperObj.value) {
        return;
      }

      return _stepperObj.value.totatStepsNumber;
    });

    const { resetForm, handleSubmit } = useForm<Step1 | Step2 | Step3 | Step4>({
      validationSchema: currentSchema
    });

    const previousStep = () => {
      if (!_stepperObj.value) {
        return;
      }

      currentStepIndex.value--;

      _stepperObj.value.goPrev();
    };

    const handleStep = handleSubmit(values => {
      for (const item in values) {
        // eslint-disable-next-line no-prototype-builtins
        if (values.hasOwnProperty(item)) {
          if (values[item]) {
            formData.value[item] = values[item];
          }
        }
      }

      currentStepIndex.value++;

      if (!_stepperObj.value) {
        return;
      }

      _stepperObj.value.goNext();
    });
   
    
    const formSubmit =  () => {  
      // console.log("formSubmit: " + formData.value.accountType);
      context.emit('accountTypeSelected', formData.value.accountType);
      return;
    };

    resetForm({
      values: {
        ...formData.value
      }
    });

    return {
      createAccountRef,
      totalSteps,
      previousStep,
      handleStep,
      formSubmit,
      currentStepIndex,
      formData,
      planType
    };
  }
});
